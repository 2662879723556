@import 'themes';

@import '~@nebular/theme/styles/globals';

@include nb-install() {
  @include nb-theme-global();
}
/* You can add global styles to this file, and also import other style files */

.buttons {
  button {
    margin-left: 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

tr {
  &.hoverable {
    transition: all 0.2s;

    &:hover {
      background: rgba(0, 0, 0, 0.055);
    }
  }
}

nb-stepper {
  .step-content {
    flex-grow: 1;
  }
}
